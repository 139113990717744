/* Fonts */
:root {
  padding: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  /* --font-default: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
  /* --font-primary: "Inter", sans-serif; */
  /* --font-secondary: "Poppins", sans-serif; */
}

/* Colors */
:root {
  --color-default: #0a0d13;
  --color-primary: #0d42ff;
  --color-secondary: #0e1d34;
}

/* Smooth scroll behavior */
:root {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245/var(--tw-bg-opacity));
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


